* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: 'Fira Sans', sans-serif, 'Noto Sans Armenian', "Roboto", sans-serif;
  font-weight: 600;
}

body {
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
}

button {
  cursor: pointer;
}

.block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.block .slider, .block .slick-list {
  width: 100%;
}

.block a, .block .slick-track {
  display: flex!important;
}

.block .slick-slide {
  margin: 10px;
}

/*.slider {
  width: 100%;
  display: flex;
  gap: 30px;
  !*height: 300px;*!
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  !*animation: text 50s infinite linear;*!
  !*margin-left: 30%;*!
  !*padding-left: 100%;*!
  white-space: nowrap;
  font-size: 24px;
  font-weight: 700;
}*/

@keyframes text {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

.slide {
  /*top: 0;*/
  width: calc(100% / 9);
  cursor: pointer;
  /*height: 100%;*/
  /*opacity: 0;*/
}

.slide.active {
  /*opacity: 1;*/
}


.slider {
  display: flex;
}

.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.modal *:not(img, .control-dots){
  height: 100%;
}

.control-dots {
  height: unset;
  bottom: 50px;
}

.modal img {
  width: fit-content!important;
  max-width: 80%;
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.close {
  color: white;
  right: 50px;
  position: absolute;
  top: 20px;
  font-size: 50px;
  cursor: pointer;
  z-index: 1000;
}

/*.modal img {
  display: block;
  margin: auto;
  width: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}*/

